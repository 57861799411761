
import react from 'react';
import { useState } from 'react'
import '../2_style/App.css';
import '../2_style/Header.css';
import '../2_style/ScrollBarre.css';
import '../2_style/Bouton.css';
import '../2_style/image.css';

import Connexion from '../1_composant/Connexion.js';
import Config from '../1_composant/Config';
import Inscription from '../1_composant/Inscription';

import IconCompte from "../3_image/IconCompte.png";





function App() {
  const IP="158.69.63.53"



  const [SelectPage, setSelectPage] = useState("0");
  const [SelectTableauDeBord, setSelectTableauDeBord] = useState("0");
  const [DataUser, setDataUser] = useState([]);





  const ClickAcceuil = () => {
      setSelectPage("0")
  }




  var input = <h1>Erreur</h1>;
  switch (SelectPage) {
    case "0": input = <Connexion  setSelectPage={setSelectPage} setDataUser={setDataUser}  IP={IP} />
      ; break
    case "1": input = <Config setSelectPage={setSelectPage} DataUser={DataUser} IP={IP} />
      ; break
    case "2": input = <Inscription  />
      ; break
    case "3": input = <div>Case 3</div>
      ; break
    case "4": input = <div>Case 4</div>
      ; break
    case "5": input = <div>Case 5</div>
      ; break
    case "6": input = <div>Case 6</div>
      ; break
  }













  return (
    <div id="App">
      <header>
        <div  onClick={(e) => ClickAcceuil()} >
          <h1>Sui<b>Vie</b></h1>
          <h2>by Simon Lst</h2>
        </div>
        <img src={IconCompte} id="IconCompte" />
      </header>

      {input}


    </div>
  );
}

export default App;
