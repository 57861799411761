import react from 'react';
import { useState, useEffect } from 'react'
import axios, { Axios } from 'axios'

import sage from "../3_image/sage.png"
import graph from "../3_image/graph.png"
import Voiture from "../3_image/Voiture.png"


export default Connexion;

function Connexion({ setSelectPage, setDataUser, IP }) {

  const [IDUser, setIDUser] = useState("");
  const [MDPUser, setMDPUser] = useState("");






  return (

    <div id="BodyApp">

      <div id='divSection' >
        {/* <img src={sage} id="sage" /> */}
        <section style={{ width: "min(90%, 500px)" }}>
        <h3>Inscription 📑</h3>

          <label >Genre</label>
          <select id="Inputconnexion" type="email" name="Email" onChange={(e) => setIDUser(e.target.value)} />
          
          <label>Prenom</label>
          <input id="Inputconnexion" type="email" name="Email" onChange={(e) => setIDUser(e.target.value)} />
          <label >Nom</label>
          <input id="Inputconnexion" type="password" name="MDP" onChange={(e) => setMDPUser(e.target.value)} />

          <h3></h3>

          <label >Adresse e-mail</label>
          <input id="Inputconnexion" type="email" name="Email" onChange={(e) => setIDUser(e.target.value)} />
          <label >Mot de passe</label>
          <input id="Inputconnexion" type="password" name="MDP" onChange={(e) => setMDPUser(e.target.value)} />

          
          <div id='divConnexion'>
            <button id="connexion" /*onClick={(e) => ClickConnexion()}*/>S'inscrire</button>
          </div>
        </section>




      </div>



      <div id='divSection'>
      <img src={sage} id="sage2" title=" 🗨️ Faire le sage en mode prise de note"/>
  
      </div>










    </div>


  );

}



