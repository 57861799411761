import react from 'react';
import { useState, useEffect } from 'react'
import axios, { Axios } from 'axios'
import '../2_style/Parametre.css';

export default MouvementIN;

function MouvementIN({ DataUser, IP,setSelectGraph }) {


  const [Categorie, setCategorie] = useState("16");
  const [Projet, setProjet] = useState("1");
  const [Montant, setMontant] = useState("0");
  const [Commentaire, setCommentaire] = useState("");



  const Save = () => {
    setCategorie("16")
    setProjet("1")
    setMontant("0")
    setCommentaire("")
    setSelectGraph("1")

    console.log(Commentaire)

    axios.post("http://" + IP + ":3003/Save_MouvementPonctuel", {
      Mail: DataUser.Mail,
      MotsDePasse: DataUser.MotsDePasse,
      Identification: DataUser.Identification,

      Categorie: Categorie,
      Projet: Projet,
      Montant: Montant,
      Commentaire: Commentaire,



    }).then((response) => {

    });
  };















  return (


    <section id="sectionParametre">



      <h3>Mouvement Ponctuel</h3>




      <div id="parametreList">



        <div id="parametreLigne" >
          <label for="Categorie" title=" 🗨️">Categorie</label>
          <div id="parametreInput">
            <select name="Categorie"
              value={Categorie} onChange={(e) => setCategorie(e.target.value)}>
              <optgroup >   {/*  label="Regulier" */}
                <option value="16">🤷‍♂️ Chépa</option>
                <option value="5">🥑 Alimentation</option>
                <option value="18">🥡 Midi Exterieur</option>
                <option value="6">🍣 Restaurant</option>
                <option value="9">🎨 Loisire</option>
                <option value="17">🍺 soirée</option>
                <option value="19">🚬 Adiction</option>
              </optgroup><optgroup>
                <option value="11">🚝 Transport</option>
                <option value="10">👨‍⚕️ Médical</option>
              </optgroup><optgroup>
                <option value="8">🏘️ Maison</option>
                <option value="7">🚗 Voiture</option>
                <option value="14">🦦 Animaux</option>
                <option value="15">👗🪑 Mode et decoration</option>
              </optgroup><optgroup>
                <option value="12">💸 Retrait</option>
              </optgroup>
            </select>

          </div>
        </div>

        <div id="parametreLigne" >
          <label for="Projet" title=" 🗨️">Projet</label>
          <div id="parametreInput">
            <select name="Projet"
              value={Projet} onChange={(e) => setProjet(e.target.value)}>
              <option value="1">🔄 Quotidien</option>
            </select>

          </div>
        </div>


        <div id="parametreLigne" >
          <label for="Montant" title=" 🗨️">Montant</label>
          <div id="parametreInput">
            <input type="number" name="Montant"
              value={Montant} onChange={(e) => setMontant(e.target.value)} />
            <span>€</span>
          </div>
        </div>

        <div id="parametreLigne" >
          <label for="Commentaire" title=" 🗨️">Commentaire</label>
          <div id="parametreInput">
            <input type="text" name="Commentaire"
              value={Commentaire} onChange={(e) => setCommentaire(e.target.value)} />
          </div>
        </div>


      </div>

      <div id='divConnexion'>
        <button id="connexion" onClick={(e) => Save()} >Enregistré</button>
      </div>


    </section>

  );
}





