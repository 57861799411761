import react from 'react';
import { useState, useEffect } from 'react'
import axios, { Axios } from 'axios'

import sage from "../3_image/sage.png"
// import graph from "../3_image/graph.png"
// import Voiture from "../3_image/Voiture.png"


export default Connexion;

function Connexion({ setSelectPage, setDataUser, IP }) {

  const [IDUser, setIDUser] = useState("");
  const [MDPUser, setMDPUser] = useState("");
  const [ErreurConnexion, setErreurConnexion] = useState(<a id='MDPOublie'></a>);



  const ClickConnexion = () => {

    // setErreurConnexion(<a id='MDPOublie'>connexion au serveur impossible</a>)

    axios.post("http://" + IP + ":3003/connection_USER", {
      IDUser: IDUser,
      MDPUser: MDPUser

    }).then((response) => {

      setDataUser(response.data[0])

      if (response.data[0].Identification === "køteβ2ξOgxφ3ωfSθ6YεQ") {
        setTimeout(() => {
          setSelectPage("1")
        }, 300);
      } else if (response.data == "[ConnexionNoFind]") {
        console.log("erreur")

        const connexion = document.querySelector('#connexion');

        setTimeout(function () {
          connexion.blur();
          setErreurConnexion(<a id='MDPOublie'>Mots de passe oublié ?</a>);
        }, 300);

      } else {
        setErreurConnexion(<a id='MDPOublie'>Le serveur est en panne</a>);

      }
    });
  };



const ChangePage =(id) =>{
setSelectPage(id);
};







  return (

    <div id="BodyApp">
{/* <div id='divSection'>

<img src={graph} id="graph" />

</div> */}

      <div id='divSection' >
        {/* <img src={sage} id="sage" /> */}
        <section style={{ width: "min(90%, 500px)" }}>
          <h3>Bienvenue 🤗</h3>
          {/* <p>Pour commencer, tu auras besoin de te connecter 👇 </p> */}

          <label >Adresse e-mail</label>
          <input id="Inputconnexion" type="email" name="Email" onChange={(e) => setIDUser(e.target.value)} />
          <label >Mot de passe</label>
          <input id="Inputconnexion" type="password" name="MDP" onChange={(e) => setMDPUser(e.target.value)} />

          {ErreurConnexion}

          <div id='divConnexion'>
            <button id="connexion" onClick={(e) => ClickConnexion()}>Connexion</button>
            <button id="inscription" onClick={(e) => ChangePage("2")} >Inscription</button>
          </div>
        </section>




      </div>

      <div id='divSection'>
      <img src={sage} id="sage2" title=" 🗨️ Bonjour et bienvenu 😉, je m'appelle Dr Soud. Je peux vous aider a utiliser et comprendre mon simulateur."/>
        <section >
          <h3></h3>
          <p>🗨️ Sur ce site, tu vas découvrir des outils simples et puissance pour t'aider à organiser ta vie.
            Ton planning, tes notes, le suivie de tes depenses et la gestion de tes projets, tout ca au meme endroit !
          </p>
        </section>
      </div>
{/*

      <div id='divSection'>
      <img src={graph} id="graph" title=" 🗨️ Ce graphique montre l'evolution du courants, de la tension et de la vitesse en fonction de la consigne d'acceleration"/>
      <section >
          <h3>Methode de simulation 📈</h3>
          <p>🗨️ Ma méthode de calcul temporel permet de simuler le comportement d'un vehcicule en fonction des composants utilisés 
          ainsi que des reglages specifiques.
          Tu peux l'utiliser pour évaluer la faisabilité de tes projets, mettre en évidence l'influence de tes choix techniques, 
          optimiser les coûts, améliorer les performances de tes véhicules, et même anticiper leurs faiblesses.
          </p>
        </section>
      </div>


      

      <div id='divSection'>
      <img src={Voiture} id="Voiture" title=" 🗨️ Chaque element du véhicule est pris en compte dans les caluls ce qui permet de s'adapter a n'importe quelle vehicule."/>
      <section >
          <h3>Base de donnees 🔩</h3>
          <p>🗨️ Cette outils utilise une base de donnée construite par la communauté.   
          Cette base regroupe les caracteristiques techniques de differents composant comme des moteurs, des Batterie...
          Si tu ne trouve pas un composant, tu peux l'ajouter.
          Un guide t'aidera à recurper les données techniques necessaire aux calculs. 
          </p>
        </section>


      </div>
      <div id='divSection'></div>


*/}







    </div>


  );

}



