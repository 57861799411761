import react from 'react';
import { useState, useEffect } from 'react'
import axios, { Axios } from 'axios'
import '../2_style/Parametre.css';

export default Home;

function Home({ DataUser, IP }) {





  const [Data_MouvementPonctuel,setData_MouvementPonctuel]= useState([]);
  const Get_MouvementPonctuel = () => {
    // console.log("Get_MouvementPonctuel EN cours")
    axios.post("http://" + IP + ":3003/Get_MouvementPonctuel", {
      Mail: DataUser.Mail,
      MotsDePasse: DataUser.MotsDePasse,
      Identification: DataUser.Identification, 

    }).then((response) => {

      console.log("Get_MouvementPonctuel")
      console.log(response.data)
      setData_MouvementPonctuel(response.data)
    })
  };
  useEffect(() => {

    Get_MouvementPonctuel()

  }, []);
















  return (


    <section id="sectionParametre">



      <h3>Mouvement Ponctuel</h3>


<table>
      {Data_MouvementPonctuel.map((Data) => (
        <tr>
<td>{Data.Projet}</td>
<td>{Data.Categorie}</td>
<td>{Data.Montant}1€</td>
<td>{Data.Mois}</td>
</tr>

))}
</table>






    </section>

  );
}





