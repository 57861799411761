import react from 'react';
import { useState, useEffect } from 'react'
import '../2_style/Menu.css';

import Home from '../1_composant/Home';
import MouvementIN from '../1_composant/MouvementIN';
//import Projet from '../1_composant/Projet';






import axios, { Axios } from 'axios'



export default Config;

function Config({ setSelectPage, DataUser, IP }) {



  const [IdConfig, setIdConfig] = useState("1");

  const [SelectGraph, setSelectGraph] = useState("1");
  const [SelectParametre, setSelectParametre] = useState("1");


  var Graph = <h1>🚧</h1>; //graph
  switch (SelectGraph) {
    case "0": Graph = <h1>🏗️</h1>
      ; break
    case "1": Graph = <Home DataUser={DataUser} IP={IP} />
      ; break
    case "2": Graph = <MouvementIN DataUser={DataUser} IP={IP} setSelectGraph={setSelectGraph} /> 
      ; break
    case "3": Graph = <h1>🏗️</h1>
          ; break
    case "4": Graph = <h1>🏗️</h1>
          ; break
    case "5": Graph = <h1>🏗️</h1>
          ; break
    case "6": Graph = <h1>🏗️</h1>
      ; break
    case "7": Graph = <h1>🏗️</h1>
      ; break
  }




  var DivGraph = <h1>🚧</h1>;
 
    DivGraph =
      <div id='divSectionGraph'>

        <nav>
          <ol>

            <li><button className={`special-button ${SelectGraph === "1" ? "selected" : ""}`} onClick={(e) => ClickSelectGraph("1")}>🏡</button></li>
            <li><button className={`special-button ${SelectGraph === "2" ? "selected" : ""}`}  onClick={(e) => ClickSelectGraph("2")}>📂</button></li>
            <li style={{ visibility: 'hidden' }}></li>
            <li style={{ visibility: 'hidden' }}></li>
            <li style={{ visibility: 'hidden' }}></li>
            <li><button className={`special-button ${SelectGraph === "3" ? "selected" : ""}`}  onClick={(e) => ClickSelectGraph("3")}>📈</button></li>
            <li><button className={`special-button ${SelectGraph === "4" ? "selected" : ""}`}  onClick={(e) => ClickSelectGraph("4")}>🧀</button></li>
            <li><button className={`special-button ${SelectGraph === "5" ? "selected" : ""}`}  onClick={(e) => ClickSelectGraph("5")}>📃</button></li>
            <li><button /*disabled*/ className={`special-button ${SelectGraph === "6" ? "selected" : ""}`}  onClick={(e) => ClickSelectGraph("6")}>⚗️</button></li>
            <li><button /*disabled*/ className={`special-button ${SelectGraph === "7" ? "selected" : ""}`}  onClick={(e) => ClickSelectGraph("7")}>📊</button></li>
          </ol>
        </nav>

        {Graph}
      </div>

  
  const ClickSelectGraph = (i) => {
    setSelectGraph(i)
  }


  return (

    <div id="BodyApp2">

      {DivGraph}

    </div>


  );

}



